import React from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';
import withStyles from 'isomorphic-style-loader/withStyles';
import s from './Header.css';
import { Navbar, Nav, NavDropdown } from 'react-bootstrap';
import Link from '../Link';
import Navigation from '../Navigation';
import LanguageSwitcher from '../LanguageSwitcher';
import logoUrl from './WOOBERLY-LOGO.png';
import logoUrl2x from './WOOBERLY-LOGO.png';
import { connect } from 'react-redux'
import messages from '../../locale/messages';
import { changeExt } from 'upath';
import cx from 'classnames';
import { api, logoUploadDir } from '../../config'
import { flowRight as compose } from 'lodash';
import history from '../../core/history';

class Header extends React.Component {
  render() {
    const { logo, logoHeight, logoWidth, siteName } = this.props;    
    let location;
    if (history && history.location) {
      location = history.location.pathname;
    }

    return (
      <div className={s.root}>
        <Navbar expand="lg" className={cx(s.navBg, 'homeHeaderCss', { [s.homeHeader]: location === '/' || location === '/home' })}>
          <Navbar.Brand>
            <Link className={s.brand} to="/">
              <img
                src={api.apiEndpoint + logoUploadDir + logo}
                width={Number(logoWidth)}
                height={Number(logoHeight)}
                alt={siteName}
              />
            </Link>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" className={cx(s.borderNone, s.outlineNone)}
            children={
              <div className={'menuToggle'}>
                <input type="checkbox" />
                <span></span>
                <span></span>
                <span></span>
              </div>
            }
          />
          <Navbar.Collapse id="basic-navbar-nav" className={s.justifyFlexEnd}>
            <Navigation />
          </Navbar.Collapse>

        </Navbar>
      </div>
    )
  }
}

const mapState = (state) => ({
  logo: state.siteSettings.data.homeLogo,
  logoHeight: state.siteSettings.data.logoHeight,
  logoWidth: state.siteSettings.data.logoWidth,
  siteName: state.siteSettings.data.siteName
})
const mapDispatch = {

}

export default compose(
  withStyles(s),
  connect(mapState, mapDispatch)
)(Header)
