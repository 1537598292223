import React from 'react';

class FbScript extends React.Component {
    render() {
        return (
            <div>
                <div id="fb-root"></div>
                {/* <!-- Your Plugin de discussion code --> */}
                <div id="fb-customer-chat" className="fb-customerchat">
                </div>
                <script dangerouslySetInnerHTML={{
                    __html: `
                    var chatbox = document.getElementById('fb-customer-chat');
                    chatbox.setAttribute("page_id", "107097344454726");
                    chatbox.setAttribute("attribution", "biz_inbox");
                `}}>

                </script>
                {/* <!-- Your SDK code --> */}
                <script dangerouslySetInnerHTML={{
                    __html: `
                window.fbAsyncInit = function() {
                    FB.init({
                        xfbml: true,
                        version: 'v12.0'
                    });
                    };
                    (function(d, s, id) {
                    var js, fjs = d.getElementsByTagName(s)[0];
                    if (d.getElementById(id)) return;
                    js = d.createElement(s); js.id = id;
                    js.src = 'https://connect.facebook.net/fr_FR/sdk/xfbml.customerchat.js';
                    fjs.parentNode.insertBefore(js, fjs);
                    }(document, 'script', 'facebook-jssdk'));
                `}}>

                </script>
            </div>
        )
    }
}

export default FbScript;