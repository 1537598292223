// General
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cookie from 'react-cookies'
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';

// Bootstrap
import {
  Button,
  Container, Row, Col, Form
} from 'react-bootstrap';

// Style
import withStyles from 'isomorphic-style-loader/withStyles';
import cx from 'classnames';
import s from './CookiesDisclaimer.css';

// Locale
import messages from '../../locale/messages';
import { url } from '../../config';
class CookiesDisclaimer extends Component {

  static propTypes = {
    formatMessage: PropTypes.func,
  };

  constructor(props) {
    super(props);
    this.state = {
      isCookiesSet: false,
      isPageLoad: false,
    }
    this.disclamierForm = this.disclamierForm.bind(this);
  }

  componentWillMount() {
    let cookiesValue = cookie.load('cookiesDisclaimer');
    this.setState({
      isCookiesSet: (cookiesValue) ? true : false
    });
  }

  componentDidMount() {
    let cookiesValue = cookie.load('cookiesDisclaimer');
    this.setState({
      isCookiesSet: (cookiesValue) ? true : false,
      isPageLoad: true
    });
  }

  disclamierForm() {
    let maxAge = 3650 * 24 * 365;
    cookie.save('cookiesDisclaimer', 'DrimonaDisclaimer', {
      path: '/',
      maxAge
    })
    this.setState({ isCookiesSet: true })
  }

  render() {
    const { formatMessage } = this.props.intl;
    const { isCookiesSet, isPageLoad } = this.state;
    const { siteName } = this.props;

    if (isCookiesSet) {
      return <span />;
    } else {
      return (
        <Container fluid>
          {
            isPageLoad && <div className={cx(s.root, s.fixedPosition)}>
              <div className={cx(s.cookiesBackground)}>
                <div className={s.displayTableSection}>
                  <div className={cx(s.labelText)}>
                    {siteName}
                    {formatMessage(messages.cookiesDisclaimer)} <a href='/page/politique-de-confidentialité' target='_blank'>{formatMessage(messages.cookiesDisclaimerLink)}</a>
                  </div>
                  <div className={s.btnSection}>
                    <Button type="button" className={cx(s.button, s.btnPrimary)} onClick={this.disclamierForm}>
                      {formatMessage(messages.accepterLabel)}
                    </Button>
                    <Button type="button" className={cx(s.button, s.btnSecondary)} onClick={this.disclamierForm}>
                      {formatMessage(messages.refuserLabel)}
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          }
        </Container>
      )
    }
  }
}

const mapState = state => ({
  siteName: state.siteSettings.data.siteName
});

const mapDispatch = {};
export default injectIntl(withStyles(s)
  (
    connect(mapState, mapDispatch)
      (CookiesDisclaimer)
  )
);