require('dotenv').config();
/* eslint-disable max-len */

if (process.env.BROWSER) {
  // throw new Error(
  //   'Do not import `config.js` from inside the client-side code.',
  // );
}

module.exports = {
  // default locale is the first one
  locales: [
    /* @intl-code-template '${lang}-${COUNTRY}', */
    'en-US'
    /* @intl-code-template-end */
  ],

  // Node.js app
  port: process.env.PORT || 3000,

  // https://expressjs.com/en/guide/behind-proxies.html
  trustProxy: process.env.TRUST_PROXY || 'loopback',

  // SITE URL
  siteUrl: process.env.SITE_URL || 'https://www.drimona.com',

  // API Gateway
  api: {
    // API URL to be used in the client-side code
    clientUrl: process.env.API_CLIENT_URL || '',
    // API URL to be used in the server-side code
    serverUrl:
      process.env.API_SERVER_URL ||
      `http://localhost:${process.env.PORT || 3000}`,

    apiEndpoint: process.env.API_ENDPOINT_URL || 'https://www.drimona.com/api'
  },

  // Database
  databaseUrl: process.env.DATABASE_URL,

  // Maximum upload size
  maxUploadSize: process.env.MAX_UPLOAD_SIZE || 5,

  // Licence Upload
  licenseuploadDir: process.env.LICENSE_UPLOAD_DIR || '/images/license/',

  // Vehicle Upload
  vehicleUploadDir: process.env.VEHICLE_UPLOAD_DIR || '/images/vehicle/',

  // Profile photo upload
  profilePhotouploadDir: process.env.PROFILE_PHOTO_UPLOAD_DIR || '/images/avatar/',

  // category photo upload
  categoryUploadDir: process.env.CATEGORY_PHOTO_UPLOAD_DIR || '/images/category/',

  // Logo photo upload
  logoUploadDir: process.env.LOGO_PHOTO_UPLOAD_DIR || '/images/logo/',

  // homepage photo upload
  homepageUploadDir: process.env.HOMEPAGE_UPLOAD_DIR || '/images/homepage/',

  // staticpage photo upload
  staticpageUploadDir: process.env.STATICPAGE_UPLOAD_DIR || '/images/staticpage/',

  //Content page photo upload
  contentPageUploadDir: process.env.CONTENTPAGE_UPLOAD_DIR || '/images/contentPage/',

  // Push Notification Server Key
  serverKey: 'AAAAmiyU2_A:APA91bHZE_AkGZGkvmL97TRrUsesEOZcDioRBiUTdfSQDSwnYsjPitJA9ulBrDWfYEf5P29xJmuRKN6-9ZDKjEeabs8wQ8_lPjm3klGQ6TARxJY7W3tYLtbHGka6XzR0zQSmztjYWYe7',

  //Google map api key
  googleMapAPI: process.env.GOOGLE_MAP_API || 'AIzaSyB6grGC5NEMCjenMulgIu3A0DFS_qLQpYw',

  // Web analytics
  analytics: {
    // https://analytics.google.com/
    googleTrackingId: process.env.GOOGLE_TRACKING_ID || 'UA-175028794-1', // UA-XXXXX-X
  },

  // Payment - Stripe
  payment: { /* From ENV */
    stripe: {
        secretKey: process.env.STRIPE_SECRET,
    }
  },

referralPlayStoreURL: {
    rider: 'https://play.google.com/store/apps/details?id=com.drimona.drimona',
    dirver: 'https://play.google.com/store/apps/details?id=com.drimona.drimonadriver'
  },

  referralAppStoreURL: {
    rider: 'https://apps.apple.com/us/app/drimona/id1531662141',
    dirver: 'https://apps.apple.com/us/app/drimona-driver/id1531665385'
  },

  adminId: 'cd1cc030-1bdc-11ea-9e8f-179abe411c92',

  // Authentication
  auth: {
    jwt: { secret: process.env.JWT_SECRET },

    // https://developers.facebook.com/
    facebook: {
      id: process.env.FACEBOOK_APP_ID || '186244551745631',
      secret:
        process.env.FACEBOOK_APP_SECRET || 'a970ae3240ab4b9b8aae0f9f0661c6fc',
    },

    // https://cloud.google.com/console/project
    google: {
      id:
        process.env.GOOGLE_CLIENT_ID ||
        '251410730550-ahcg0ou5mgfhl8hlui1urru7jn5s12km.apps.googleusercontent.com',
      secret: process.env.GOOGLE_CLIENT_SECRET || 'Y8yR9yZAhm9jQ8FKAL8QIEcd',
    },

    // https://apps.twitter.com/
    twitter: {
      key: process.env.TWITTER_CONSUMER_KEY || 'Ie20AZvLJI2lQD5Dsgxgjauns',
      secret:
        process.env.TWITTER_CONSUMER_SECRET ||
        'KTZ6cxoKnEakQCeSpZlaUCJWGAlTEBJj0y2EMkUBujA7zWSvaQ',
    },
  },
  adminId: 'cd1cc030-1bdc-11ea-9e8f-179abe411c92',
adminVatRate: 1.20,

  adminVatPercent: 20,

  userVatRate: 1.10,

  userVatPercentage: 10,
emailConfig: {
host: process.env.SMTP_HOST || 'smtp.sendgrid.net',
port: process.env.SMTP_PORT || 25,
email: process.env.SMTP_LOGIN_EMAIL || 'apikey',
sender: process.env.SMTP_FROM_NAME || 'Drimona',
senderEmail: process.env.SMTP_SENDER_EMAIL || 'contact@drimona.com',
password: process.env.SMTP_LOGIN_PASSWORD ,
secure: process.env.SMTP_SECURE || false,
tls: process.env.SMTP_TLS || false,
},
 referralPlayStoreURL: {
    rider: 'https://play.google.com/store/apps/details?id=com.drimona.drimona',
    dirver: 'https://play.google.com/store/apps/details?id=com.drimona.drimonadriver'
  },

  referralAppStoreURL: {
    rider: 'https://apps.apple.com/us/app/drimona/id1531662141',
    dirver: 'https://apps.apple.com/us/app/drimona-driver/id1531665385'
  },

contactEmail: 'contact@drimona.com'
};
